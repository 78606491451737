import { motion } from "framer-motion";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { OCol, ORow } from "../../../../Styled/CustomGrid";
import { DesignPhase2Props, designPhase2Config } from "./constants";

import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { STEP_DESIGN, STEP_INSTALLATION } from "../../../../../store/reducer";
import useIsMobile from "../../../../../utils/useIsMobile";
import { OActionButton } from "../../../../Styled/Buttons/OActionButton";
import { fade } from "../../../../Styled/GlobalAnimations";
import { OBody8, OBody9, OH1, OH2 } from "../../../../Styled/Texts";
import { Orientation2D } from "../../../../shared/panels_grid/constants";
import KitPreview from "../../../../shared/virtual-cart";
import { DesignReduxStepData } from "../../constant";
import SectionManager from "./section_manager";
import { Section } from "./sections_content/styled";
import { SectionContainer } from "./styled";

import { useIsCalepinageHmValidated } from "../../../../../hooks/configurateur/calepinage/use-calepinage-hm-validated";
import { useHotjarFeedback } from "../../../../../hooks/hotjar/use-hotjar-feedback";
import { useSimulation } from "../../../../../hooks/simulation/use-simulation";
import { CustomerServiceCard } from "../../../../shared/customer-service/customer-service-card";
import MatrixEditor from "../../../../shared/matrix-editor";
import MatrixViewer from "../../../../shared/matrix_viewer";
import { InstallationType } from "../../../Installation/constants";
import { AdministrativeHelpModal } from "./administrative_help/administrative-help-modal";
import { AlimentationModal } from "./alimentation/alimentation-modal";
import { BatteryModal } from "./battery/battery-modal";
import { CalepinageHmConfirmOverlay } from "./calepinage-hm/calepinage-hm-confirm-overlay";
import {
  ConfigurateurPopupContext,
  ConfigurateurPopups,
} from "./configurateur-modals-context";
import { InstallHelpModal } from "./install-help/install-help-modal";
import { PanelsModal } from "./panels/panels-modal";
import { VirtualCartPopup } from "./virtual-cart-popup/virtual-cart-popup";
import { WarrantyModal } from "./warranty/warranty-modal";
import { GLOBAL_SHOW_CRC, GLOBAL_SHOW_VALIDATE_CALEPINAGE } from "../../../../shared/constant";

const DesignPhase2: React.FC<DesignPhase2Props> = ({
  canReturn = true,
  onContinue,
  onReturn,
}) => {
  const currentStepName = STEP_DESIGN;
  //let needGetPanelCount = true;
  const { t } = useTranslation([currentStepName, "global"]);
  const config = designPhase2Config;
  const isMobile = useIsMobile();

  const hasSkipped = useSelector((state: RootState) => state.step.skip);
  const stepData = useSelector(
    (state: RootState) => state.step.steps[currentStepName].data
  ) as DesignReduxStepData;

  const isMatrixEditable = useSelector(
    (state: RootState) => state.calepinageHMReducer.isMatrixEditable
  );

  const installationData = useSelector(
    (state: RootState) => state.step.steps[STEP_INSTALLATION].data
  );

  const isRoof = installationData?.installation === InstallationType.Roof;

  const panelCountInCalepinage = useSelector(
    (state: RootState) => state.calepinageReducer.panelCountInCalepinage
  );

  const roofMatriceCalepinage = useSelector(
    (state: RootState) => state.calepinageReducer.matriceCalepinage
  );
  const fixation = useSelector(
    (state: RootState) => state.calepinageReducer?.fixationSelected
  );
  const rooforientationCalepinage = useSelector(
    (state: RootState) => state.calepinageReducer.dispositionPanneaux
  );
  const [orientation, setOrientation] = useState<Orientation2D>(
    stepData?.phase1?.panelsGrid?.orientation
  );

  const shouldDisplayCalepinageMap = useSelector(
    (state: RootState) => state.step.steps[STEP_DESIGN].data.phase1?.display
  );

  const [isCartExpanded, setIsCartExpanded] = useState(false);

  useEffect(() => {
    if (stepData?.phase1?.panelsGrid) {
      setOrientation(stepData.phase1.panelsGrid.orientation);
    }
  }, [stepData?.phase1?.panelsGrid]);

  let selectedMatrix = [[]];

  // Cas hors toiture get grid data to make a matrice to display
  const fixationSelected = useSelector((state: RootState) => {
    const row = state.calepinageReducer?.fixationSelected?.l;
    const column = state.calepinageReducer?.fixationSelected?.c;
    selectedMatrix = _.fill(Array(row), _.fill(Array(column), 1)) || [[]];
    return state.calepinageReducer?.fixationSelected || {};
  });

  const [popup, setPopup] = useState(ConfigurateurPopups.none);
  const panels = useSelector(
    (state: RootState) => state.configurateurPanels?.panelsData?.data
  );
  const isCalepinageHmValidated = useIsCalepinageHmValidated();

  useHotjarFeedback();

  useSimulation();

  return (
    <ConfigurateurPopupContext.Provider value={{ popup, setPopup }}>
      <AdministrativeHelpModal show={popup === ConfigurateurPopups.popupHelp} />
      <InstallHelpModal show={popup === ConfigurateurPopups.popupInstallHelp} />
      <WarrantyModal show={popup === ConfigurateurPopups.popupWarranty} />
      <BatteryModal show={popup === ConfigurateurPopups.popupBattery} />
      <AlimentationModal
        show={popup === ConfigurateurPopups.popupAlimentation}
      />
      <PanelsModal
        show={popup === ConfigurateurPopups.popupPanelDetails}
        panels={panels}
      />
      {isMobile && (
        <VirtualCartPopup
          show={isCartExpanded}
          onClose={() => setIsCartExpanded(false)}
        />
      )}
      <ORow className="h-100 overflow-auto">
        <OCol
          sm={config.gridConfig.left[0]}
          md={config.gridConfig.left[1]}
          lg={config.gridConfig.left[2]}
          className="d-flex flex-column justify-content-between align-items-center contentsized locked position-sticky top-0 no-padding h-100 d-none d-md-flex"
        >
          {!isMobile && (
            <KitPreview
              full={true}
              isExpanded={isCartExpanded}
              setIsExpanded={setIsCartExpanded}
            />
          )}

          {isRoof && hasSkipped && <MatrixEditor></MatrixEditor>}

          {isRoof && shouldDisplayCalepinageMap && !hasSkipped && (
            <div className="mt-auto mb-auto pb-5 d-none d-md-block overflow-auto">
              <MatrixViewer
                {...fade}
                matrix={roofMatriceCalepinage}
                orientation={rooforientationCalepinage}
              ></MatrixViewer>
            </div>
          )}

          {!isRoof && (
            <div className="mt-auto mb-auto pb-5 d-none d-md-block">
              <MatrixViewer
                {...fade}
                matrix={selectedMatrix}
                orientation={fixation?.orientation}
              ></MatrixViewer>
            </div>
          )}

          {/* 3D Grid container */}
          {/* <PanelsGridContainer canvasMode={true} mapMode={false} />  */}
        </OCol>

        <OCol
          sm={config.gridConfig.right[0]}
          md={config.gridConfig.right[1]}
          lg={config.gridConfig.right[2]}
          className="position-relative d-flex flex-column justify-content-between align-items-center no-padding"
        >
          {(!isCalepinageHmValidated && GLOBAL_SHOW_VALIDATE_CALEPINAGE) && <CalepinageHmConfirmOverlay />}
          <SectionContainer
            isroofanddesktop={!isRoof && !isMobile}
            className="scrollableY"
          >
            <Section>
              {isMobile ? (
                <OH1 center="true" {...fade(0.5)}>
                  <Trans> {t("phase_2.title")}</Trans>
                </OH1>
              ) : (
                <OH2 center="true" {...fade(0.5)}>
                  <Trans> {t("phase_2.title")}</Trans>
                </OH2>
              )}
              {/* <OBody8 center="true">
                <Trans> {t("phase_2.description")}</Trans>
              </OBody8> */}

              {GLOBAL_SHOW_CRC && (
                <CustomerServiceCard />
              )}
              {!isMobile && shouldDisplayCalepinageMap && (
                <>
                  {isRoof && !hasSkipped && (
                    <>
                      {/* <OH5 center="true" className="mt-3" {...fade(0.5)}>
                          <Trans> {t("phase_2.subtitle")}</Trans>
                        </OH5> */}
                      <motion.div className="d-flex flex-row justify-content-between w-100 mt-2">
                        <OBody9>
                          <Trans>{t("phase_2.panel_label_left")}</Trans>
                        </OBody9>
                        <OBody9 className="d-flex flex-column">
                          <strong>
                            {panelCountInCalepinage}&nbsp;
                            {t("phase_2.panel_label_right", {
                              count: panelCountInCalepinage,
                            })}
                            &nbsp;
                          </strong>
                          <div className="align-self-end">
                            <Trans>
                              (
                              {orientation === Orientation2D.PORTRAIT
                                ? t("portrait")
                                : t("landscape")}
                              )
                            </Trans>
                          </div>
                        </OBody9>
                      </motion.div>

                      <OActionButton onClick={onReturn} {...fade(0.3)}>
                        {t("phase_2.modify_grid")}
                      </OActionButton>
                    </>
                  )}
                </>
              )}
            </Section>

            {/* <OCol className="d-flex d-md-none justify-content-center">
              <MobileMatrixContainer>
                <MatrixViewer {...fade} matrix={selectedMatrix} orientation={orientation}></MatrixViewer>
              </MobileMatrixContainer>
            </OCol> */}

            {/* <PanelsGridContainer
                position={undefined}
                mapMode={false}
                canvasMode={false}
              /> */}

            <SectionManager
              initialConfig={config.sectionConfig}
              isCartExpanded={isCartExpanded}
              onViewCartDetailClicked={() => setIsCartExpanded(!isCartExpanded)}
            />
          </SectionContainer>
        </OCol>
      </ORow>
    </ConfigurateurPopupContext.Provider>
  );
};

export default DesignPhase2;
